import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/AuthContext';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';
import { showJoinNowPage } from '../../../services/navigation/navigation.service';
import { isWeb } from '../../../services/util-service/util.service';

import './MobileUserActions.scss';

const MobileUserActions = () => {
  const { t } = useTranslation();
  const { userLoggedIn } = useAuthContext();

  const toggleJoinNowDialogVisibility = () => {
    SignInController.showSignInDialog(true);
  };

  if (userLoggedIn || isWeb()) return null;

  return (
    <div className="MobileUserActions">
      <span onClick={showJoinNowPage}>{t('MobileUserActions.joinNow')}</span>
      <span onClick={toggleJoinNowDialogVisibility}>{t('MobileUserActions.login')}</span>
    </div>
  );
};

export default MobileUserActions;
